import "./clans"
import { AbiRegistry } from "@multiversx/sdk-core/out"
import nftStakingAbi from "helpers/mvx/abi/ecpx-nft-staking.abi.json"
import nftSaleAbi from "helpers/mvx/abi/ecpx-nft-sale.abi.json"
import battleDaoAbi from "helpers/mvx/abi/battle-dao.abi.json"
import dexAbi from "helpers/mvx/abi/onedex-sc.abi.json"

export const DexAbi = AbiRegistry.create(dexAbi)
export const NftStakingAbi = AbiRegistry.create(nftStakingAbi)
export const NftSaleAbi = AbiRegistry.create(nftSaleAbi)
export const BattleDaoAbi = AbiRegistry.create(battleDaoAbi)

export const isDev = false

export const walletConnectV2ProjectId = "4f19c3c5fb448d89a56beb97fceeb3aa"

export const contracts = {
  NftStaking: {
    address: isDev
      ? "erd1qqqqqqqqqqqqqpgqvgcgnsyklaz3g408zeddf9ltu57ja7ycngtqm4ak87"
      : "erd1qqqqqqqqqqqqqpgqazvfcw74uplnj5d5g7p04mjfrgptp7utkqysdsv7qz",
    abi: NftStakingAbi,
  },
  NftSale: {
    address: isDev
      ? "erd1qqqqqqqqqqqqqpgquppm4zpsyzrqle7wkc5026layzqd34kxkqysjdmcka"
      : "erd1qqqqqqqqqqqqqpgq6p44xrh0q0fhfatw4g0tdek7mrp6ze3fkqys4h2p6h",
    abi: NftSaleAbi,
  },
  BattleDao: {
    address: isDev
      ? "erd1qqqqqqqqqqqqqpgqyua969frj3lcm0u9f7ayldy5xemet4r6kqys4cyhmz"
      : "erd1qqqqqqqqqqqqqpgqjf9xsd3hfjpud4wq3ssqstpssfmanmstkqys0hgks5",
    abi: BattleDaoAbi,
  },
}

export const admins = [
  "erd14gmppcc30xshnv3wu6pt0s3mm8nnj782ra2cc4lkde8dftrukqysfu7kv9",
  "erd1wklku0vszrcrqrtu6nllwjntur4k6gkyc7lk8l5rjekd9aps6qwql8q708",
  "erd1ygdttzrulwfspme2s4qrx5y2qyfqalju0k2vcyy8z3979whlj9qssl5uay",
  "erd1jl3f2l0anua39pznxfl7ap6y37wwx0ud8e8mppwqnqnv87rlngtqrychj2"
]

export const owner =
  "erd1jl3f2l0anua39pznxfl7ap6y37wwx0ud8e8mppwqnqnv87rlngtqrychj2"

export const otherContracts = {
  Onedex: {
    address: isDev
      ? "erd1qqqqqqqqqqqqqpgqf32jag0ffqhx7c8vm0z7n9k84pks6epckqys7d97nl"
      : "erd1qqqqqqqqqqqqqpgqqz6vp9y50ep867vnr296mqf3dduh6guvmvlsu3sujc",
    abi: DexAbi,
  },
  Wrap0: {
    address: isDev
      ? "erd1qqqqqqqqqqqqqpgqd77fnev2sthnczp2lnfx0y5jdycynjfhzzgq6p3rax"
      : "erd1qqqqqqqqqqqqqpgqvc7gdl0p4s97guh498wgz75k8sav6sjfjlwqh679jy",
  },
  Wrap1: {
    address: isDev
      ? "erd1qqqqqqqqqqqqqpgq7ykazrzd905zvnlr88dpfw06677lxe9w0n4suz00uh"
      : "erd1qqqqqqqqqqqqqpgqhe8t5jewej70zupmh44jurgn29psua5l2jps3ntjj3",
  },
  Wrap2: {
    address: isDev
      ? "erd1qqqqqqqqqqqqqpgqfj3z3k4vlq7dc2928rxez0uhhlq46s6p4mtqerlxhc"
      : "erd1qqqqqqqqqqqqqpgqmuk0q2saj0mgutxm4teywre6dl8wqf58xamqdrukln",
  },
}

export const ElrondApiUrl = isDev
  ? "https://devnet-api.multiversx.com"
  : "https://api.multiversx.com"
export const ElrondGatewayUrl = isDev
  ? "https://devnet-gateway.multiversx.com"
  : "https://gateway.multiversx.com"
export const MAIAR_TOKENS_API =
  "https://maiartokens.com/one-dex/token-value/latest"
export const XEXCHANGE_TOKENS_API =
  "https://maiartokens.com/token-value/latest/all"
export const ONEDEX_API = isDev
  ? "https://api.onedex.app/devnet"
  : "https://api.onedex.app"
export const ECPX_TOKEN_ID = isDev ? "" : "ECPX-5cbfeb"
export const WEGLD_TOKEN_ID = isDev ? "WEGLD-d7c6bb" : "WEGLD-bd4d79"

export const BackendApiUrl = isDev
  ? "http://127.0.0.1:3033"
  : "https://web-production-6650.up.railway.app"
    // :"http://127.0.0.1:3033"
