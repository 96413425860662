import React, { useEffect, useState } from "react"
import { useGetPendingTransactions } from "@multiversx/sdk-dapp/hooks"
import { ExplorerLink } from "@multiversx/sdk-dapp/UI"
import { BackendApiUrl, contracts } from "configs"
import { CustomPagination } from "components/CustomPagination"
import { Col, Container, Input, Row, Spinner, Table } from "reactstrap"
import { BsFillCircleFill } from "react-icons/bs"
import {
  convertSecondsToPrintDateTimeString,
  convertWeiToEsdt,
  shortenAddress,
} from "utils"
import {
  getAccountTransactions,
  getAccountTransactionsCount,
  getNfts,
} from "utils/elrond-api"
import axios from "axios"
import { CSVLink } from "react-csv"
import moment from "moment"
import Papa from "papaparse"
import Spinners from "components/Common/Spinner"

const BattleStats = () => {
  const [refresh, setRefresh] = useState(false)
  const [loading, setLoading] = useState(false)
  const { hasPendingTransactions } = useGetPendingTransactions()

  useEffect(() => {
    const timer = setTimeout(() => {
      setRefresh(!refresh)
    }, 30000)

    return () => clearTimeout(timer)
  }, [refresh])

  const pageSize = 15
  const [pageCount, setPagecount] = useState(1)
  const [pageIndex, setPageIndex] = useState(0)

  function onGotoPage(value) {
    if (value < 0) return
    if (value >= pageCount) return
    setPageIndex(value)
  }

  const [actions, setActions] = useState([])
  const [actionClans, setActionClans] = useState([])
  const [exportData, setExportData] = useState([])
  const [clanFilter, setClanFilter] = useState("")
  const [playerFilter, setPlayerFilter] = useState("")
  const [battleFilter, setBattleFilter] = useState("")
  const [battles, setBattles] = useState([])

  const loadActions = async () => {
    setLoading(true)
    const response = await axios.get(
      BackendApiUrl + "/history/getVoteHistories",
      {
        params: {
          page: pageIndex + 1,
          rows_per_page: 15,
          clan_name: clanFilter,
          sender_address: playerFilter,
          battle_id: battleFilter,
        },
      }
    )
    setLoading(false)
    setActions(response.data.results)
    setPagecount(Math.ceil(response.data.total_count / pageSize))
    setPageIndex(response.data.current_page - 1)
  }

  const loadBattles = async () => {
    const response = await axios.get(BackendApiUrl + "/history/getBattles")
    setBattles(response.data)
  }

  const handleExport = async event => {
    const response = await axios.get(
      BackendApiUrl + "/history/getVoteHistories",
      {
        params: {
          export_csv: true,
          clan_name: clanFilter,
          sender_address: playerFilter,
          battle_id: battleFilter,
        },
      }
    )

    const data = response.data.results
    const csv = Papa.unparse(data)
    console.log(csv)
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" })
    const link = document.createElement("a")

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob)
    link.setAttribute("href", url)
    link.setAttribute("download", "data.csv")
    link.style.visibility = "hidden"
    document.body.appendChild(link)

    // Simulate a click on the link to trigger the download
    link.click()

    // Clean up
    document.body.removeChild(link)
    URL.revokeObjectURL(url)
    setExportData(data)
  }

  useEffect(() => {
    if (hasPendingTransactions) return // ;(async () => {
    //   const _txCount = await getAccountTransactionsCount(
    //     contracts.BattleDao.address,
    //     "voteBattle"
    //   )
    //   const _pageCount = Math.max(
    //     Math.floor((_txCount + pageSize - 1) / pageSize),
    //     1
    //   )
    //   setPagecount(_pageCount)
    // })() // ;(async () => {
    //   const txs = await getAccountTransactions(
    //     contracts.BattleDao.address,
    //     "voteBattle",
    //     pageIndex * pageSize,
    //     (pageIndex + 1) * pageSize
    //   )
    //   // console.log('txs', txs);
    //   const _actions = txs.map(tx => {
    //     let power = 0
    //     let nftId = ""
    //     if (
    //       tx.action?.arguments?.transfers &&
    //       tx.action.arguments.transfers.length > 0
    //     ) {
    //       for (const transfer of tx.action.arguments.transfers) {
    //         if (transfer.type == "FungibleESDT" && transfer.ticker == "ECPX") {
    //           power = convertWeiToEsdt(transfer.value).toNumber()
    //         } else if (
    //           transfer.type == "NonFungibleESDT" &&
    //           transfer.ticker == "ECPX"
    //         ) {
    //           nftId = transfer.identifier
    //         }
    //       }
    //     }
    //     return {
    //       timestamp: tx.timestamp,
    //       hash: tx.txHash,
    //       player: tx.sender,
    //       status: tx.status,
    //       power,
    //       nftId,
    //     }
    //   })
    //   // console.log('_actions', _actions);
    //   setActions(_actions)
    // })()
    loadActions()
    loadBattles()
  }, [hasPendingTransactions, pageIndex])

  useEffect(() => {
    if (actions.length == 0) return
    ;(async () => {
      const _actionClans = []
      const nftIds = actions.map(action => action.nftId)
      // console.log('nftIds', nftIds);
      const nfts = await getNfts(nftIds)
      // console.log('nfts', nfts);
      for (const action of actions) {
        for (const nft of nfts) {
          if (action.nftId == nft.identifier) {
            let actionClan = ""
            if (nft?.metadata?.attributes) {
              // console.log({action, nft});
              for (const attr of nft.metadata.attributes) {
                if (attr.trait_type == "Clan") {
                  actionClan = attr.value
                  break
                }
              }
            }
            _actionClans.push(actionClan)
            break
          }
        }
      }
      // console.log('_actionClans', _actionClans);
      setActionClans(_actionClans)
    })()
  }, [actions])

  return (
    <React.Fragment>
      <div className="page-content">
        <h5 className="mb-4 text-center">Battle Stats</h5>
        <div className="d-flex search-filter">
          <input
            className="form-control"
            value={clanFilter}
            onChange={e => {
              setClanFilter(e.target.value)
            }}
            placeholder={`Search Clan.`}
          />
          <input
            value={playerFilter}
            className="form-control"
            onChange={e => {
              setPlayerFilter(e.target.value)
            }}
            placeholder={`Search Player.`}
          />
          <select
            id="custom-select"
            className="form-select"
            value={battleFilter}
            onChange={e => {
              setBattleFilter(e.target.value || undefined)
            }}
          >
            <option value="">All</option>
            {battles.map(option => (
              <option key={option.id} value={option.id}>
                {`Battle ${option.id} ${moment
                  .unix(option.start_time)
                  .format("YYYY-MM-DD hh:mm")} ~ ${moment
                  .unix(option.end_time)
                  .format("YYYY-MM-DD hh:mm")}`}
              </option>
            ))}
          </select>
          <button className="search-btn" onClick={loadActions}>
            Search
          </button>
          <button className="export-btn" onClick={handleExport}>
            Export CSV
          </button>
        </div>

        <Row className="recent-activity">
          <Col xs="12">
            <div className="mb-2">
              <CustomPagination
                pageCount={pageCount}
                pageIndex={pageIndex}
                pageSize={pageSize}
                gotoPage={onGotoPage}
              />
            </div>

            {loading ? (
              <Spinner
                color="primary"
                className="position-absolute top-50 start-50"
              />
            ) : (
              <div className="table-responsive">
                <Table className="ecpx-table mb-0">
                  <thead>
                    <tr className="text-center">
                      <th>#</th>
                      <th>Time</th>
                      <th>Hash</th>
                      <th>Player</th>
                      <th>Supported</th>
                      <th>Status</th>
                      <th>Power</th>
                    </tr>
                  </thead>
                  <tbody>
                    {actions.length > 0 &&
                      actions.map((action, index) => (
                        <tr key={index}>
                          <td className="text-center">
                            {pageIndex * pageSize + index + 1}
                          </td>
                          <td className="font-code">
                            {convertSecondsToPrintDateTimeString(
                              Date.now() / 1000 - action.timestamp
                            )}{" "}
                            ago
                          </td>
                          <td className="font-code text-center">
                            <ExplorerLink
                              page={`transactions/${action.hash}`}
                              text={shortenAddress(action.hash, 10)}
                            />
                          </td>
                          <td className="font-code text-center">
                            {shortenAddress(action.sender, 10)}
                          </td>
                          <td className="text-center">
                            {action.clan_name ?? "-"}
                          </td>
                          <td className="text-center">
                            <BsFillCircleFill
                              style={{
                                color:
                                  action.status == "success"
                                    ? "#198754"
                                    : action.status == "fail"
                                    ? "#fa113d"
                                    : "#ffff99",
                              }}
                            />{" "}
                            {action.status}
                          </td>
                          <td>{`${action.amount} ECPX`}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>

                {actions.length == 0 && (
                  <span className="text-center">No Activity</span>
                )}
              </div>
            )}

            <div>
              <CustomPagination
                pageCount={pageCount}
                pageIndex={pageIndex}
                pageSize={pageSize}
                gotoPage={onGotoPage}
              />
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default BattleStats
